import {
  HelpcenterSectionType,
  ExtendedTicket,
  HelpcenterPageType,
  HelpcenterPageNames,
  User,
  CompanyDisplayInfo,
  Article,
  Category,
  ResolvedCustomField,
  TicketTimeline,
  PremiumStatus,
} from '@wix/answers-api';
import { CategoryArticles } from '../server/rpc/types/response-dto';
import { ErrorType } from './errors/error-type';
import { TenantInfo } from './tenant-info.i';

export enum ComponentTypes {
  Header = 'header',
  Footer = 'footer',
  NavSidebar = 'sidebar',
}

export interface CodePack {
  template: string;
  type: string;
}

export enum BlueprintsExtraTypes {
  relatedArticles = 'related-articles',
  articleInfo = 'article-info',
  categoriesEmptyState = 'categories-empty-state',
  profile = 'profile',
  ticket = 'ticket',
  customerPortalTicket = 'customer-portal-ticket',
  ticketList = 'ticket-list',
}

export enum HARDCODED_SECTION_TYPES {
  PROFILE = 'profile',
  TICKET = 'ticket',
}
export enum ExtraPageType {
  PROFILE = 1000,
  TICKET = 1100,
}
export const PROFILE_PAGE_TYPE = (ExtraPageType.PROFILE as unknown) as HelpcenterPageType;
export const TICKET_PAGE_TYPE = (ExtraPageType.TICKET as unknown) as HelpcenterPageType;

export const PROFILE_PAGE_NAME = 'profilePage' as HelpcenterPageNames;
export const TICKET_PAGE_NAME = 'ticketPage' as HelpcenterPageNames;

export type HelpcenterUser = User & { companies?: CompanyDisplayInfo[] };
export interface Blueprint extends CodePack {
  type: 'blueprint';
  style: string;
}

export interface TemplateFromBlueprint extends CodePack {
  type: 'rendered-blueprint';
}

export interface TemplateFromOverride extends CodePack {
  type: 'override';
}

export type SectionBlueprints = {
  [key in
    | HelpcenterSectionType
    | ComponentTypes
    | BlueprintsExtraTypes]: Blueprint;
};

export type SectionRenderedBlueprints = {
  [key in
    | HelpcenterSectionType
    | ComponentTypes
    | BlueprintsExtraTypes]: TemplateFromBlueprint;
};
export const segmentTypeMap: {
  [key in
    | HelpcenterSectionType
    | ComponentTypes
    | BlueprintsExtraTypes]: string;
} = {
  [HelpcenterSectionType.HERO]: 'hero',
  [HelpcenterSectionType.CATEGORIES]: 'categories',
  [HelpcenterSectionType.CORE_CATEGORIES]: 'categories',
  [HelpcenterSectionType.CORE_SUB_CATEGORIES]: 'categories',
  [HelpcenterSectionType.CORE_ARTICLE]: 'article',
  [HelpcenterSectionType.CORE_ARTICLE_FEATURE_REQUEST]: 'article',
  [HelpcenterSectionType.CORE_ARTICLE_KNOWN_ISSUE]: 'article',
  [HelpcenterSectionType.ARTICLE_LIST]: 'article-list',
  [HelpcenterSectionType.CORE_ARTICLE_LIST]: 'core-article-list',
  [HelpcenterSectionType.CONTACT_FORM]: 'contact-form',
  [HelpcenterSectionType.CORE_SEARCH_RESULTS]: 'search-results',
  [HelpcenterSectionType.CORE_ERROR]: 'error',
  [HelpcenterSectionType.CUSTOM]: 'custom',
  [HelpcenterSectionType.CORE_REQUEST_LIST]: 'ticket-list',
  [HelpcenterSectionType.CORE_REQUEST_PAGE]: 'customer-portal-ticket',
  [HelpcenterSectionType.FOLLOWED_ARTICLES]: 'followed-articles',
  [ComponentTypes.Header]: 'header',
  [ComponentTypes.Footer]: 'footer',
  [ComponentTypes.NavSidebar]: 'navigation-sidebar',
  [BlueprintsExtraTypes.relatedArticles]: 'related-articles-list',
  [BlueprintsExtraTypes.articleInfo]: 'article-info',
  [BlueprintsExtraTypes.categoriesEmptyState]: 'categories-empty-state',
  [BlueprintsExtraTypes.profile]: 'profile',
  [BlueprintsExtraTypes.ticket]: 'ticket',
  [BlueprintsExtraTypes.ticketList]: 'ticket-list',
  [BlueprintsExtraTypes.customerPortalTicket]: 'customer-portal-ticket',
};

export const pageTypeMap: {
  [key in HelpcenterPageType]: HelpcenterPageNames;
} = {
  [HelpcenterPageType.HOMEPAGE]: HelpcenterPageNames.HOMEPAGE,
  [HelpcenterPageType.CONTACT]: HelpcenterPageNames.CONTACT,
  [HelpcenterPageType.CATEGORIES]: HelpcenterPageNames.CATEGORIES,
  [HelpcenterPageType.SUB_CATEGORIES]: HelpcenterPageNames.SUB_CATEGORIES,
  [HelpcenterPageType.CATEGORY]: HelpcenterPageNames.CATEGORY,
  [HelpcenterPageType.ARTICLE]: HelpcenterPageNames.ARTICLE,
  [HelpcenterPageType.ARTICLE_FEATURE_REQUEST]:
    HelpcenterPageNames.ARTICLE_FEATURE_REQUEST,
  [HelpcenterPageType.ARTICLE_KNOWN_ISSUE]:
    HelpcenterPageNames.ARTICLE_KNOWN_ISSUE,
  [HelpcenterPageType.SEARCH_RESULTS]: HelpcenterPageNames.SEARCH_RESULTS,
  [HelpcenterPageType.ERROR]: HelpcenterPageNames.ERROR,
  [HelpcenterPageType.REQUEST_LIST]: HelpcenterPageNames.REQUEST_LIST,
  [HelpcenterPageType.REQUEST_PAGE]: HelpcenterPageNames.REQUEST_PAGE,
  [HelpcenterPageType.FOLLOWED_ARTICLES]: HelpcenterPageNames.FOLLOWED_ARTICLES,
};

export interface SectionModel {
  locale: string;
  currentPage?: HelpcenterPageType;
  category?: Category;
  categoryTree?: Category[];
  subcategories?: Category[];
  categoryArticles?: CategoryArticles[];
  subcategoryArticles?: CategoryArticles[];
  parentCategoryArticles?: CategoryArticles[];
  featuredArticles?: Article[];
  knownIssues?: Article[];
  featureRequests?: Article[];
  errorType?: boolean | ErrorType;
  rootCategoriesArticles?: CategoryArticles[];
  followingArticles?: Article[];
  articles?: Article[];
  article?: Article;
  relatedArticles?: Article[];
  tenant?: TenantInfo;
  sessionUser?: HelpcenterUser;
  tickets?: ExtendedTicket[];
  itemsCount?: number;
  numPages?: number;
  customerPortalEnabled: boolean;
  filtersApplied?: boolean;
  loading?: boolean;
  searchQuery?: string;
  contactFormFields?: ResolvedCustomField;
  customerTimeline?: TicketTimeline;
  premium?: PremiumStatus;
}
