import { ExtraPageType } from '../../../common/helpcenter-types';
import {
  Article,
  Category,
  ContactFormInitialFieldData,
  CustomFieldsConfigurationData,
  HelpcenterPageType,
  Ticket,
  User,
  ArticleSearchDTO,
} from '@wix/answers-api';
import { HelpcenterEvent } from './helpcenter-event';

export interface AnswersSdk {
  currentPage: string;
  currentLanguage: string;
  currentUser?: User;
  onPageLoad(cb: SdkOnPageLoadEventHandler): void;
  onContactFormFieldsChanged(cb: SdkOnPageLoadEventHandler): void;
  addEventListener(cb: (evet: HelpcenterEvent) => void): void;
  setCustomFieldsConfiguration(fields: CustomFieldsConfigurationData[]): void;
  setContactFormData(data: ContactFormInitialFieldData): void;
  setShowCCField(shouldShow: boolean): void;
  setShowPartnerCompanyField(data: ShowPartnerCompanyFieldData): void;
  setContentFilters(data: ContentFilters): void;
}

export enum SdkEventType {
  PAGE_LOAD = 'page_load',
  CONTACT_FORM_FIELDS_CHANGED = 'contact_form_fields_changed',
}

type AvailablePageTypes = HelpcenterPageType | ExtraPageType;

type SdkOnPageLoadEventBase<T extends keyof SdkOnPageLoadEventDataMap> = {
  pageType: T;
  locale: string;
} & SdkOnPageLoadEventDataMap[T];

interface SdkOnPageLoadEventDataMap {
  [HelpcenterPageType.ARTICLE]: {
    article: Article;
    relatedArticles: Article[];
  };
  [HelpcenterPageType.ARTICLE_FEATURE_REQUEST]: {
    article: Article;
    relatedArticles: Article[];
  };
  [HelpcenterPageType.ARTICLE_KNOWN_ISSUE]: {
    article: Article;
    relatedArticles: Article[];
  };
  [HelpcenterPageType.CATEGORIES]: {
    categoryTree: Category[];
  };
  [HelpcenterPageType.SUB_CATEGORIES]: {
    category: Category;
    subcategories: Category[];
  };
  [HelpcenterPageType.CATEGORY]: {
    category: Category;
    articles: Article[];
  };
  [HelpcenterPageType.SEARCH_RESULTS]: {
    results: Article[];
    searchTerm: string;
    itemsCount: number;
  };
  [HelpcenterPageType.CONTACT]: {
    fieldsData?: ContactFormInitialFieldData;
  };
  [HelpcenterPageType.HOMEPAGE]: {};
  [HelpcenterPageType.ERROR]: {
    errorCode: number;
  };
  [HelpcenterPageType.REQUEST_LIST]: {};
  [HelpcenterPageType.REQUEST_PAGE]: {};
  [ExtraPageType.PROFILE]: {
    user: User;
  };
  [ExtraPageType.TICKET]: {
    ticket: Ticket;
  };
  [HelpcenterPageType.FOLLOWED_ARTICLES]: {
    user: User;
  };
}

export type SdkOnPageLoadEventData =
  | SdkOnPageLoadEventBase<HelpcenterPageType.ARTICLE>
  | SdkOnPageLoadEventBase<HelpcenterPageType.ARTICLE_FEATURE_REQUEST>
  | SdkOnPageLoadEventBase<HelpcenterPageType.ARTICLE_KNOWN_ISSUE>
  | SdkOnPageLoadEventBase<HelpcenterPageType.CATEGORIES>
  | SdkOnPageLoadEventBase<HelpcenterPageType.CATEGORY>
  | SdkOnPageLoadEventBase<HelpcenterPageType.CONTACT>
  | SdkOnPageLoadEventBase<HelpcenterPageType.ERROR>
  | SdkOnPageLoadEventBase<HelpcenterPageType.HOMEPAGE>
  | SdkOnPageLoadEventBase<HelpcenterPageType.SEARCH_RESULTS>
  | SdkOnPageLoadEventBase<HelpcenterPageType.SUB_CATEGORIES>
  | SdkOnPageLoadEventBase<HelpcenterPageType.REQUEST_LIST>
  | SdkOnPageLoadEventBase<HelpcenterPageType.REQUEST_PAGE>
  | SdkOnPageLoadEventBase<ExtraPageType.PROFILE>
  | SdkOnPageLoadEventBase<ExtraPageType.TICKET>
  | SdkOnPageLoadEventBase<HelpcenterPageType.FOLLOWED_ARTICLES>;

export interface SdkOnContactFormChangedEventData {
  pageType: AvailablePageTypes;
  fieldsData: ContactFormInitialFieldData;
}

export interface ShowPartnerCompanyFieldData {
  shouldShow: boolean;
  mandatory?: boolean;
}
export type ContentFilters = Partial<ArticleSearchDTO>;

export type SdkOnPageLoadEventHandler = (
  event: Event,
  data: SdkOnPageLoadEventData,
) => void;

export type SdkOnContactFormChangedEventHandler = (
  event: Event,
  data: SdkOnContactFormChangedEventData,
) => void;

export type SdkEventHandler =
  | SdkOnPageLoadEventHandler
  | SdkOnContactFormChangedEventHandler;
