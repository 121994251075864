import { createEmitter } from '@wix/answers-lib';
import { ContactFormInitialFieldData, CustomFieldsConfigurationData, User } from '@wix/answers-api';
import {
  SdkOnPageLoadEventData,
  SdkEventType,
  AnswersSdk,
  SdkOnContactFormChangedEventData,
  SdkEventHandler,
  ShowPartnerCompanyFieldData,
  ContentFilters,
} from './domain';
import { HelpcenterEvent } from './helpcenter-event';

const EVENT_PREFIX = 'answersEvent';

const createSdkEmitter = <T>(namespace: string) =>
  createEmitter<T>(undefined, namespace);

const publishSdkEvent = (eventType: SdkEventType, data: unknown) =>
  window.dispatchEvent(
    new CustomEvent(getSdkEventName(eventType), { detail: data }),
  );

const isValidEventType = (type) => {
  const typeVal = type && type.toString();
  return Object.values(SdkEventType).indexOf(typeVal) !== -1;
};

export const getSdkEventName = (eventType: SdkEventType) =>
  `${EVENT_PREFIX}.${eventType}`;

export const subscribeToSdk = (type: SdkEventType) => (cb: SdkEventHandler) => {
  if (isValidEventType(type)) {
    window.addEventListener(
      getSdkEventName(type) as any,
      (event: CustomEvent) => {
        cb(event, event.detail);
      },
    );
  } else {
    throw new Error('Unknown event type: ' + type);
  }
};

export const publishOnPageLoadSdkEvent = (data: SdkOnPageLoadEventData) => {
  const sdk = getAnswersSdk();
  sdk.currentPage = data?.pageType !== undefined ? `${data?.pageType}` : 'N/A';
  sdk.currentLanguage = data?.locale !== undefined ? `${data?.locale}` : 'N/A';
  publishSdkEvent(SdkEventType.PAGE_LOAD, data);
};

export const setCurrentUserSdk = (currentUser?: User) =>
  (getAnswersSdk().currentUser = currentUser);

export const publicOnContactFormChangeSdkEvent = (
  data: SdkOnContactFormChangedEventData,
) => {
  publishSdkEvent(SdkEventType.CONTACT_FORM_FIELDS_CHANGED, data);
};

export const sdkCustomFieldsConfigurationChangedEmitter = createSdkEmitter<
  CustomFieldsConfigurationData[]
>('customFieldsConfigurationData');

export const sdkSetShowCCFieldEmitter = createSdkEmitter<boolean>('setShowCCField');

export const sdkEventListenersEmitter = createSdkEmitter<HelpcenterEvent>('eventListener')

export const sdkSetShowPartnerCompanyFieldEmitter = createSdkEmitter<ShowPartnerCompanyFieldData>('setShowPartnerCompanyField');

export const sdkSetContentFiltersEmitter = createSdkEmitter<ContentFilters>('setContentFilters');

export const sdkContactFormFieldsDataEmitter = createSdkEmitter<
  ContactFormInitialFieldData
>('contactFormFieldsData');

export const cleanAnswersSdk = () => {
  const isSSR = typeof window === 'undefined';
  if (!isSSR) {
    window.Answers = null;
  }
};

export const getAnswersSdk = (): AnswersSdk => {
  try {
    return window.Answers;
  } catch (e:any) {
    throw new Error(`Error getting AnswersSdk from window: ${e.message}`);
  }
};
